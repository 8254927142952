<template>
  <v-container
    id="client-forms"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row justify="center">
      <v-col
        v-if="!loading"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[{text: 'Ваш центр', to: '/'}, {text: 'Преподаватели', to: '/teachers'}, {text: title}]"
          large
        />
      </v-col>
      <v-col
        cols="12"
        md="10"
        lg="8"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          v-if="!loading"
          color="success"
          icon="mdi-badge-account"
          class="px-5 py-3"
          :title="title"
        >
          <v-form
            ref="form"
            class="mt-5"
            lazy-validation
          >
            <v-row
              justify="center"
            >
              <v-col
                v-if="teacher.status === 0"
                cols="12"
                md="12"
              >
                <base-material-alert
                  v-if="teacher.status === 0"
                  color="warning"
                  dark
                  icon="mdi-zip-disk"
                >
                  Преподаватель в архиве!
                </base-material-alert>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="teacher.name"
                  required
                  label="Имя*"
                  :error-messages="fieldError('name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="teacher.phone"
                  required
                  label="Телефон"
                  :error-messages="fieldError('phone')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="teacher.email"
                  required
                  label="Email"
                  :error-messages="fieldError('email')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  ref="menu2"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  min-width="290px"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      :value="formatDate(teacher.birthday)"
                      label="День рождения"
                      :error-messages="fieldError('birthday')"
                      color="secondary"
                      prepend-icon="mdi-calendar-outline"
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="teacher.birthday"
                    color="secondary"
                    locale="ru-ru"
                    landscape
                    scrollable
                    first-day-of-week="1"
                    @input="menu3 = false"
                  >
                    <v-spacer />

                    <v-btn
                      color="secondary"
                      large
                      @click="menu3 = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-textarea
                  v-model="teacher.comment"
                  :error-messages="fieldError('comment')"
                  rows="1"
                  auto-grow
                  label="Примечание"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="teacher.tax"
                  required
                  label="Ставка за академический час"
                  :error-messages="fieldError('tax')"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-textarea
                  v-model="teacher.description"
                  :error-messages="fieldError('description')"
                  rows="1"
                  auto-grow
                  label="Описание 'О себе' для сайта"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="teacher.link"
                  required
                  label="Ссылка на профиль"
                  :error-messages="fieldError('link')"
                />
              </v-col>
              <v-col
                v-if="teacher.photo"
                cols="12"
                md="6"
              >
                <v-card
                  flat
                  tile
                  class="d-flex"
                >
                  <v-img
                    :src="imageLink(teacher.photo)"
                    :lazy-src="imageLink(teacher.photo)"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-file-input
                  v-model="photo"
                  :error-messages="fieldError('photo')"
                  color="deep-purple accent-4"
                  label="Фото"
                  show-size
                  placeholder="Выберите фото"
                  prepend-icon="mdi-paperclip"
                  outlined
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-actions class="pl-0">
                  <v-btn
                    color="success"
                    min-width="100"
                    @click="prepareSaveTeacher()"
                  >
                    Сохранить
                  </v-btn>

                  <v-btn
                    v-if="teacher.status === 1"
                    color="warning"
                    min-width="100"
                    @click="archive()"
                  >
                    Переместить в архив
                  </v-btn>

                  <v-btn
                    v-if="teacher.status === 0 && teacher.id > 0"
                    color="warning"
                    min-width="100"
                    @click="unarchive()"
                  >
                    Вернуть из архива
                  </v-btn>

                  <v-btn
                    v-if="teacher.status === 0 && teacher.id > 0"
                    color="error"
                    class="ma-1"
                    min-width="100"
                    @click="confirmDialog = true"
                  >
                    Удалить
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить преподавателя?
        </v-card-title>

        <v-card-text>
          Преподаватель будет удален без возможности восстановления.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="removeTeacher(teacher.id)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import teacherApi from '../services/TeachersApi'
  import { hasRole, OfficeOwner } from '../services/jwt.service'

  export default {
    props: ['id'],
    data () {
      return {
        photo: null,
        teacher: {
          id: null,
          name: null,
        },
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        confirmDialog: false,
        courses: [{ id: null, name: ' - не выбрано - ' }],
        currencies: [],
        menu3: false,
        date: '',
      }
    },

    computed: {
      title () {
        if (this.teacher.name) return this.teacher.name
        if (this.id === 'create') return 'Добавить преподавателя'
        return '#' + this.id
      },
    },

    mounted () {
      document.title = this.title + ' | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      this.fetchData()
    },

    methods: {
      fetchData () {
        if (this.id === 'create') {
          this.loading = false
          this.teacher = {
            id: null,
            name: null,
            created: moment().format('YYYY-MM-DD HH:mm:ss'),
            status: 1,
          }
        } else {
          teacherApi
            .fetchTeacher(this.id)
            .then(response => {
              this.teacher = response
              this.loading = false
              document.title = this.title + ' | ' + process.env.VUE_APP_NAME
            }).catch(error => {
              this.error = this.pretty(error.response.data)
            })
        }
      },

      back () {
        this.$router.push('/')
      },

      prepareSaveTeacher () {
        if (this.photo) {
          this.getBase64(this.photo).then(
            data => {
              this.teacher.photoData = data
              this.saveTeacher()
            })
        } else {
          this.saveTeacher()
        }
      },

      saveTeacher () {
        if (this.teacher.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          teacherApi
            .updateTeacher(this.teacher)
            .then(response => {
              this.teacher = response
              this.fetchData()
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        } else {
          teacherApi
            .createTeacher(this.teacher)
            .then(response => {
              this.teacher = response
              this.$router.push('/teacher/' + this.teacher.id)
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        }
      },

      removeTeacher () {
        if (hasRole(OfficeOwner)) {
          this.confirmDialog = false
          this.loading = true
          teacherApi
            .deleteTeacher(this.teacher.id)
            .then(response => {
              // this.template = response
              this.loading = false
              this.$router.go(-1)
            }).catch(error => {
              this.error = this.pretty(error.response.data)
            })
        } else {
          alert('Недостаточно прав для удаления! Обратитесь к руководителю центра!')
        }
      },

      archive () {
        this.teacher.status = 0
        this.saveTeacher()
      },
      unarchive () {
        this.teacher.status = 1
        this.saveTeacher()
      },
      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },

      imageLink (photo) {
        return process.env.VUE_APP_OLD_URL + 'images/teachers/' + photo
      },

      getBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
    },
  }
</script>
