import axios from 'axios'

export default {
  fetchTeachers () {
    return axios.get('teachers').then(response => {
      return response.data
    })
  },

  fetchActiveTeachers () {
    return axios.get('teachers?status=1').then(response => {
      return response.data
    })
  },

  fetchBirthdaysTeachers () {
    return axios.get('teachers/birthdays').then(response => {
      return response.data
    })
  },

  fetchTeacher (id) {
    return axios.get('teachers/' + id).then(response => {
      return response.data
    })
  },

  createTeacher (teacher) {
    return axios.post('teachers', teacher).then(response => {
      return response.data
    })
  },

  updateTeacher (teacher) {
    return axios.put('teachers/' + teacher.id, teacher).then(response => {
      return response.data
    })
  },

  deleteTeacher (id) {
    return axios.delete('teachers/' + id).then(response => {
      return response.data
    })
  },

  orderUp (id) {
    return axios.post('teachers/order', { id: id, move: 'up' }).then(response => {
      return response.data
    })
  },

  orderDown (id) {
    return axios.post('teachers/order', { id: id, move: 'down' }).then(response => {
      return response.data
    })
  },
}
